<template>
  <div class="wrapper">
    <div class="card">
      <div class="title">
        <div class="caption">{{ $t('customer.unused.title') }}</div>
      </div>
      <div class="content" style="color: #686E74">
        <div>
          {{ $t('customer.unused.content') }}
        </div>
        <div v-html="$t('customer.unused.contact')" class="mt-4"></div>
      </div>
      <div class="mt-5">
        <button :disabled="status" class="btn btn-ghost btn-grey-dark" @click="home">{{ $t('home_page') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'customer.unused',
  data () {
    return {
      status: false,
      url: 'https://www.owlting.com'
    }
  },
  methods: {
    home () {
      this.status = true
      this.$store.dispatch('removeJWT')
      this.$store.dispatch('removeMode')
      localStorage.clear()
      window.location.href = this.url
      return true
    }
  }
}
</script>
